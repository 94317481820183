// Vendors
import React from 'react';

function Loader() {
  return (
    <div className="loading-overlay">
      <div className="loading-overlay__loader" />
    </div>
  );
}

export default Loader;
