// Vendors
import React, { useState, useEffect } from 'react';
import { Root, Routes } from 'react-static';
import { I18nextProvider, withTranslation } from 'react-i18next';
import { Router } from '@reach/router';
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';
import TagManager from 'react-gtm-module';

// Translations
import 'dayjs/locale/fr';
import 'dayjs/locale/en';
import i18n from '../config/i18n';

// Utilities
import { ScrollToTop } from '../utilities/scroll';

// Styles
import './scss/app.scss';

function App() {
  const [cookies, setCookies] = useState('false');
  const cookiesName = 'cc_imedia';

  useEffect(() => {
    setCookies(getCookieConsentValue(cookiesName));
  }, []);

  if (cookies === 'true' || cookies === true) {
    TagManager.initialize({
      gtmId: process.env.GOOGLE_TAGMANAGER_ID,
    });
  }

  return (
    <Root>
      <I18nextProvider i18n={i18n}>
        <ScrollToTop />
        <Router id="wrapper" primary={false}>
          <Routes path="*" />
        </Router>
        <CookieConsent
          location="none"
          buttonText={i18n.t('cookie').accept}
          declineButtonText={i18n.t('cookie').decline}
          cookieName={cookiesName}
          expires={60}
          enableDeclineButton={true}
          containerClasses="cc_consent"
          buttonWrapperClasses="cc_consent__buttons"
          disableStyles={true}
          onAccept={() => {
            setCookies('true');
          }}
          onDecline={() => {
            setCookies('false');
          }}
        >
          <p className="title">{i18n.t('cookie').title}</p>
          <p>{i18n.t('cookie').text}</p>
          <p dangerouslySetInnerHTML={{ __html: i18n.t('cookie').more }}></p>
        </CookieConsent>
      </I18nextProvider>
    </Root>
  );
}

export default withTranslation()(App);
