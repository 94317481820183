import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';

// Components
import Loader from './components/Loader';

// Static rendering
import App from './App';

export default App;

if (typeof document !== 'undefined') {
  const target = document.getElementById('root');
  const renderMethod = target.hasChildNodes()
    ? ReactDOM.hydrate
    : ReactDOM.render;

  // Render
  const render = Comp => {
    renderMethod(
      <AppContainer>
        <Suspense fallback={<Loader />}>
          <Comp />
        </Suspense>
      </AppContainer>,
      target,
    );
  };

  render(App);

  // Hot Module Replacement
  if (module && module.hot) {
    module.hot.accept('./App', () => {
      render(App);
    });
  }
}
