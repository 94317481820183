

import React from 'react'
import universal, { setHasBabelPlugin } from 'react-universal-component'

setHasBabelPlugin()

const universalOptions = {
  loading: () => null,
  error: props => {
    console.error(props.error);
    return <div>An error occurred loading this page's template. More information is available in the console.</div>;
  },
  ignoreBabelRename: true
}

const t_0 = universal(import('__react_static_root__/src/containers/Error'), universalOptions)
      t_0.template = '__react_static_root__/src/containers/Error'
      
const t_1 = universal(import('__react_static_root__/src/containers/Home'), universalOptions)
      t_1.template = '__react_static_root__/src/containers/Home'
      
const t_2 = universal(import('__react_static_root__/src/containers/About'), universalOptions)
      t_2.template = '__react_static_root__/src/containers/About'
      
const t_3 = universal(import('__react_static_root__/src/containers/Management'), universalOptions)
      t_3.template = '__react_static_root__/src/containers/Management'
      
const t_4 = universal(import('__react_static_root__/src/containers/Services'), universalOptions)
      t_4.template = '__react_static_root__/src/containers/Services'
      
const t_5 = universal(import('__react_static_root__/src/containers/Signature'), universalOptions)
      t_5.template = '__react_static_root__/src/containers/Signature'
      
const t_6 = universal(import('__react_static_root__/src/containers/Construction'), universalOptions)
      t_6.template = '__react_static_root__/src/containers/Construction'
      
const t_7 = universal(import('__react_static_root__/src/containers/City'), universalOptions)
      t_7.template = '__react_static_root__/src/containers/City'
      
const t_8 = universal(import('__react_static_root__/src/containers/Rental'), universalOptions)
      t_8.template = '__react_static_root__/src/containers/Rental'
      
const t_9 = universal(import('__react_static_root__/src/containers/District'), universalOptions)
      t_9.template = '__react_static_root__/src/containers/District'
      
const t_10 = universal(import('__react_static_root__/src/containers/Neighborhood'), universalOptions)
      t_10.template = '__react_static_root__/src/containers/Neighborhood'
      
const t_11 = universal(import('__react_static_root__/src/containers/Rentals'), universalOptions)
      t_11.template = '__react_static_root__/src/containers/Rentals'
      
const t_12 = universal(import('__react_static_root__/src/containers/Faq'), universalOptions)
      t_12.template = '__react_static_root__/src/containers/Faq'
      
const t_13 = universal(import('__react_static_root__/src/containers/Contact'), universalOptions)
      t_13.template = '__react_static_root__/src/containers/Contact'
      
const t_14 = universal(import('__react_static_root__/src/containers/Post'), universalOptions)
      t_14.template = '__react_static_root__/src/containers/Post'
      
const t_15 = universal(import('__react_static_root__/src/containers/Blog'), universalOptions)
      t_15.template = '__react_static_root__/src/containers/Blog'
      
const t_16 = universal(import('__react_static_root__/src/containers/RentalRequest'), universalOptions)
      t_16.template = '__react_static_root__/src/containers/RentalRequest'
      
const t_17 = universal(import('__react_static_root__/src/containers/RentalRoommate'), universalOptions)
      t_17.template = '__react_static_root__/src/containers/RentalRoommate'
      
const t_18 = universal(import('__react_static_root__/src/containers/VisitRequest'), universalOptions)
      t_18.template = '__react_static_root__/src/containers/VisitRequest'
      
const t_19 = universal(import('__react_static_root__/src/containers/WorkRequest'), universalOptions)
      t_19.template = '__react_static_root__/src/containers/WorkRequest'
      
const t_20 = universal(import('__react_static_root__/src/containers/ComplaintRequest'), universalOptions)
      t_20.template = '__react_static_root__/src/containers/ComplaintRequest'
      
const t_21 = universal(import('__react_static_root__/src/containers/LeaseGuarantee'), universalOptions)
      t_21.template = '__react_static_root__/src/containers/LeaseGuarantee'
      
const t_22 = universal(import('__react_static_root__/src/containers/Contest'), universalOptions)
      t_22.template = '__react_static_root__/src/containers/Contest'
      
const t_23 = universal(import('__react_static_root__/src/containers/Privacy'), universalOptions)
      t_23.template = '__react_static_root__/src/containers/Privacy'
      
const t_24 = universal(import('__react_static_root__/src/containers/Transfer'), universalOptions)
      t_24.template = '__react_static_root__/src/containers/Transfer'
      
const t_25 = universal(import('__react_static_root__/src/containers/Career'), universalOptions)
      t_25.template = '__react_static_root__/src/containers/Career'
      

// Template Map
export default {
  '__react_static_root__/src/containers/Error': t_0,
'__react_static_root__/src/containers/Home': t_1,
'__react_static_root__/src/containers/About': t_2,
'__react_static_root__/src/containers/Management': t_3,
'__react_static_root__/src/containers/Services': t_4,
'__react_static_root__/src/containers/Signature': t_5,
'__react_static_root__/src/containers/Construction': t_6,
'__react_static_root__/src/containers/City': t_7,
'__react_static_root__/src/containers/Rental': t_8,
'__react_static_root__/src/containers/District': t_9,
'__react_static_root__/src/containers/Neighborhood': t_10,
'__react_static_root__/src/containers/Rentals': t_11,
'__react_static_root__/src/containers/Faq': t_12,
'__react_static_root__/src/containers/Contact': t_13,
'__react_static_root__/src/containers/Post': t_14,
'__react_static_root__/src/containers/Blog': t_15,
'__react_static_root__/src/containers/RentalRequest': t_16,
'__react_static_root__/src/containers/RentalRoommate': t_17,
'__react_static_root__/src/containers/VisitRequest': t_18,
'__react_static_root__/src/containers/WorkRequest': t_19,
'__react_static_root__/src/containers/ComplaintRequest': t_20,
'__react_static_root__/src/containers/LeaseGuarantee': t_21,
'__react_static_root__/src/containers/Contest': t_22,
'__react_static_root__/src/containers/Privacy': t_23,
'__react_static_root__/src/containers/Transfer': t_24,
'__react_static_root__/src/containers/Career': t_25
}
// Not Found Template
export const notFoundTemplate = "__react_static_root__/src/containers/Error"

