// Vendors
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Common translations
import fr from '../locales/fr.json';
import en from '../locales/en.json';

// Forms translations
import formsFr from '../locales/forms/fr.json';
import formsEn from '../locales/forms/en.json';

// SEO translations
import seoFr from '../locales/seo/fr.json';
import seoEn from '../locales/seo/en.json';

// Steps translations
import stepsFr from '../locales/steps/fr.json';
import stepsEn from '../locales/steps/en.json';

// Complaint translations
import complaintFr from '../locales/complaint/fr.json';
import complaintEn from '../locales/complaint/en.json';

// Config
import config from './global';
const i18n = i18next;
if (!i18n.isInitialized) {
  i18n.use(initReactI18next).use(LanguageDetector).init({
    resources: {
      en: {
        complaint: complaintEn,
        common: en,
        forms: formsEn,
        seo: seoEn,
        steps: stepsEn
      },
      fr: {
        complaint: complaintFr,
        common: fr,
        forms: formsFr,
        seo: seoFr,
        steps: stepsFr
      }
    },
    debug: process.env.NODE_ENV !== 'production',
    ns: ['complaint', 'common', 'forms', 'seo', 'steps'],
    defaultNS: 'common',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: '.'
    },
    detection: {
      order: ['path', 'cookie', 'queryString', 'htmlTag', 'localStorage'],
      caches: [],
      lookupLocalStorage: process.env.I18N_COOKIE_NAME ? process.env.I18N_COOKIE_NAME : 'i18nextLng',
      lookupSessionStorage: process.env.I18N_COOKIE_NAME ? process.env.I18N_COOKIE_NAME : 'i18nextLng',
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0
    },
    returnEmptyString: false,
    returnObjects: true,
    returnNull: false,
    supportedLngs: config.i18n.supportedLanguages,
    fallbackLng: config.i18n.fallbackLanguage,
    react: {
      useSuspense: false
    }
  });
}
export default i18n;